/* eslint-disable @typescript-eslint/no-unused-vars */
import Header from "../../components/molecules/Header";
import Banner from "../../components/molecules/Banner";
import {BgPanel, Container, Dflex, FlexColumn, Footer, Img, LogoFooter, ServicesContainer, Social} from "./styles";
import Typography from "../../components/atoms/Typography";
import Card from "../../components/molecules/Card";
import { PATH_IMAGES, groupedItems, isMobile } from "../../utils";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Formulario from "../../components/molecules/Form";
import { Carousel } from "../../components/molecules/Carousel";
import { Team } from "../../utils/Data/Team";
// @ts-ignore
import { TransitionScroll } from 'react-transition-scroll'
import 'react-transition-scroll/dist/index.css'
// @ts-ignore
import { Element } from "react-scroll";

const Services= [
    {
        title:'Custom Web and Mobile Applications',
        image:'/app.png',
        description:'Design and development of customized web and mobile applications for clients, ranging from consumer apps to enterprise solutions.'
    },
    {
        title:'E-commerce Software',
        image:'/ecommerce.png',
        description:'Creating custom e-commerce solutions, including e-commerce websites and order management systems.'
    },
    {
        title:'Technology Consulting',
        image:'/consultoria.png',
        description:'Providing strategic technology consulting and IT solutions to assist businesses in making informed decisions about their technology infrastructure.'
    },
    {
        title:'Custom RESTful API',
        image:'/backend.png',
        description:'This service involves the design, development, and deployment of a custom RESTful API tailored to meet the specific needs of a client.'
    },
]
const itemsShow = isMobile ? 1 : 3;
const Home =()=>{
    return (
    <Container>
        <Header/>
        <Element name="Home">
            <TransitionScroll baseStyle={{ transitionDuration: '1000ms' }}  hiddenStyle={{ translate: '0 80px' }}>
                <Banner/>
            </TransitionScroll>
        </Element>
        <BgPanel>
        <TransitionScroll baseStyle={{ transitionDuration: '1000ms' }}  hiddenStyle={{ translate: '0 80px' }}>
            <Typography variant="h2" fontSize="2.225rem" align="center" color="white" padding="0 2rem" lineHeight="3.5rem">
                SkyBits provides full IT support                   
            </Typography>
            <Typography variant="p" align="center"  width="20rem" fontSize="0.965rem" color="white" padding="0 3rem">
            Our professionals will make sure that the procedures,
            tools, and reporting are well planned, deployed and managed. 
            <span className="onlyDesktop">Established in February 2022, the mission of our company has 
            always been delivering excellent services to big and small business owners. 
            Providing high-quality services is achieved by a personalized approach 
            to every client. We are a customer-oriented company and always put our 
            customers’ needs in the center of everything we do. We strive to meet 
            and even exceed our customers’ 
            expectations and bring value to their lives.
            </span>
            </Typography>
            </TransitionScroll>
        </BgPanel>
        <FlexColumn id="Services">
            <Element name="Services" style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%' }}>
                <Dflex direction="column" width="100%" margin="0">
                <TransitionScroll baseStyle={{ transitionDuration: '500ms' }}  hiddenStyle={{ translate: '0 80px' }}>
                    <Typography variant="h2" margin="1rem 5.5rem" color="primary" align='center' fontSize="1.5rem">
                        SERVICES
                    </Typography>
                    <Typography variant="h3" margin="1rem 0.5rem 0 0.5rem" align="center" lineHeight="2rem">
                        We offer a wide range of services that will cover all of your business needs.
                        Here are some of the main fields we operate in:
                    </Typography>
                </TransitionScroll>
                </Dflex>
                <TransitionScroll baseStyle={{ transitionDuration: '1000ms' }}  hiddenStyle={{ translate: '0 80px' }}>
                    <ServicesContainer align="center" width="100%" margin={isMobile ? '0' : '1.5rem 0'} justifyContent={isMobile ? 'center' : 'space-between'} >
                    {Services.map(
                        (service) =>
                        <Card {...service} />
                    )}
                    </ServicesContainer>
                </TransitionScroll>
            </Element>
        </FlexColumn>
        <TransitionScroll
            baseStyle={{ transitionDuration: '1000ms' }}
            hiddenStyle={{ translate: '0 80px' }}>
                <Element name="Our Team">
                    <Carousel
                        title="OUR TEAM"
                        slider={groupedItems(Team, itemsShow)}
                        gap="5rem"
                    />
                </Element>
        </TransitionScroll>
        <Element name="Contact">
            <Formulario/>
        </Element>
        <Footer>
                <LogoFooter  margin={isMobile ? '0':'1rem'} direction={isMobile ? 'column' : 'row'} align={isMobile ? 'center' : 'start'}>
                    <Img src={`${PATH_IMAGES}/logo-white.png`} alt="logoFooter" 
                        width={50}
                        
                    />
                    <Typography variant="h6" color="white" align="center" margin={isMobile ? '0' : '0 1rem'} fontSize={isMobile ? '0.8rem' : '1rem'}>
                        SkyBits 2023
                    </Typography>
                </LogoFooter>
            <Social width="50%">
                <LinkedInIcon sx={{
                    color: 'white',
                    fontSize: '2.5rem',
                    margin: '0 1rem 0 0'
                }}
                />
                <YouTubeIcon
                    sx={{
                        color: 'white',
                        fontSize: '2.5rem',
                        margin: '0 1rem 0 0'
                    }}
                />
            </Social>
        </Footer>
    </Container>
    )
}
export default Home;