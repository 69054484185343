import Typography from "../../atoms/Typography";
import { ContainerCard } from "./styles";

const Card = ({
    title,
    image,
    description
}: {
    title: string,
    image: string,
    description: string
}
) => {
    return ( 
    <ContainerCard>
        <img src={image} alt="" height={70} style={{margin:'2rem 0.5rem 0.5rem 0.5rem'}}/>
        <Typography variant="h4" margin="0.8rem 0">
            {title}
        </Typography>
        <Typography variant="span" fontSize="0.8rem">
            {description}
        </Typography>
    </ContainerCard> 
    );
}

export default Card;