import { useState } from 'react'
import Theme from "../../../Theme";
import emailjs from '@emailjs/browser'
import { useForm } from "react-hook-form";
import { Dflex } from "../../../pages/Home/styles";
import Button from "../../atoms/Button";
import { TextArea } from "../../atoms/Textarea/styles";
import Typography from "../../atoms/Typography";
import { BgForm, Form, TextField } from "./style";
// @ts-ignore
import { TransitionScroll } from 'react-transition-scroll'
import 'react-transition-scroll/dist/index.css'

const Formulario = () => {
    const { register, handleSubmit } = useForm();
    const [isSend, setIsSend]= useState(false);
    function sendMail(data:any) {
        console.log('data', data)
        emailjs.init("MFlVP_CE3gU6nZUhA")
        emailjs.send(
            "service_2lyca5w",
            "template_hocfoer",
            data
        )
        setIsSend(true)
    }
    return ( 
        <BgForm id="Contact">
            <Dflex width="100%" justifyContent="space-around" align="center">
                <TransitionScroll baseStyle={{ transitionDuration: '1000ms' }} hiddenStyle={{ translate: '0 80px' }}>
                    <Typography variant="h3" style={{display:'flex', alignItems:'center'}} fontSize="24px" color="white" margin="1rem" padding="1rem">
                        Contact Us
                    </Typography>
                </TransitionScroll>
                <TransitionScroll
                    baseStyle={{ transitionDuration: '1000ms', width: '100%' }}
                    hiddenStyle={{ translate: '0 80px' }}>
                {!isSend ?
                <Form onSubmit={handleSubmit(sendMail)}>
                    <TextField
                        type="text"
                        label="Name"
                        {...register("name")}
                    />
                    <TextField
                        type="email" 
                        label="Email"
                        {...register("email")}
                    />
                    <TextField 
                        type="phone" 
                        label="Phone"
                        {...register("phone")}
                    />
                    <TextField 
                        type="url" 
                        label="Url"
                        {...register("url")}
                    />
                    <TextArea
                        placeholder="Comments"
                        {...register("comments")}
                    >
                    </TextArea>
                    <Button 
                        variant="outlined" 
                        type="submit" onClick={()=>{}} 
                        sx={{color:Theme.palette.white, 
                        background:Theme.palette.turquoise,
                        margin:'1rem 0', 
                        borderRadius:'10px',
                        border:'none' }}
                    >
                        send Request
                    </Button>
                </Form> :
                <TransitionScroll baseStyle={{ transitionDuration: '1000ms' }} hiddenStyle={{ translate: '0 80px' }}>
                <Dflex direction='column' align='center'>
                    <Typography variant='h2' color='white' fontSize='1.5rem'>
                    Thank you for contacting us,
                    </Typography>
                    <Typography variant='h2' color='white'>
                    we will be in touch shortly. 
                    </Typography>
                    <Typography variant='h4' color='white'>
                    Greetings
                    </Typography>
                </Dflex>
                </TransitionScroll>
            }
            </TransitionScroll>
            </Dflex>
        </BgForm>
    );
}

export default Formulario;