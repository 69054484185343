import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { CAROUSEL_NEXT_LABEL, CAROUSEL_PREV_LABEL, CarouselItem, CarouselRP, Container, Indicators, NextArrow, PrevArrow, cssTitle } from "./styles";
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Typography from "../../atoms/Typography";
import { Dflex } from "../../../pages/Home/styles";
import { PATH_IMAGES } from "../../../utils";

interface IProps {
    slider:any,
    title: string,
    gap: string,
}
const displayCount = 1
export function Carousel({
    slider,
    title,
    gap,
}: IProps) {
    const [currentIndex, setSlide] = React.useState(0)
    const propsCarousel = {
        centerMode: true,
        centerSlidePercentage: 100 / displayCount,
        selectedItem: currentIndex,
        showArrows: false,
        showThumbs: false,
        showIndicators: false,
        showStatus: false,
        slidesToShow: displayCount,
        slidesToScroll:  displayCount,
    }
    function handleArrow (currentIndex: number, type: string) {
        let currentSlider = 0
        if (type === CAROUSEL_PREV_LABEL) {
        currentSlider = (!currentIndex ? currentIndex - displayCount : currentIndex - 1) + slider.length
        }
        if (type === CAROUSEL_NEXT_LABEL) {
        currentSlider = !currentIndex ? currentIndex + displayCount : currentIndex + 1
        }
        return currentSlider % slider.length
    }

    return (
    <Container>
        {title &&
        <Typography variant="h2" margin="1rem 5.5rem" color="primary" align='center' fontSize="1.5rem">
            {title}
        </Typography>}
        {
        <PrevArrow
            onClick={
                () => setSlide(
                    (prev) => prev === slider.length ?
                    slider.length - 1 : 
                    handleArrow(currentIndex, CAROUSEL_PREV_LABEL))
            }>
            <ArrowBackIosNewIcon />
        </PrevArrow>
        }
        <CarouselRP {...propsCarousel}>
        {slider.map(
        (s: any) =>
        <div style={{ display: 'flex', width: '100%', gap: '1rem', justifyContent: 'space-around', flexWrap:'wrap'}}>
            {s.map(
                (item: any) =>
                <CarouselItem
                    key={item.name}
                    background={ PATH_IMAGES + item.image || ''}
                    onClick={() => {}}               
                ><Dflex direction="column" align="flex-end" >
                    <Typography
                        variant="h2"
                        color="black"
                        style={cssTitle}
                    >
                        {item.name}
                    </Typography>                  
                    <Typography
                        variant="h4"
                        color="black"
                        style={cssTitle}
                    >
                        {item.role}
                    </Typography>
                    <Typography
                        variant="h4"
                        color="black"
                        margin="0"
                        style={cssTitle}
                    >
                        {item.role2}
                    </Typography>
                </Dflex>
                </CarouselItem>
            )}
        </div>)}
        </CarouselRP>
        {slider.length > displayCount &&
        <NextArrow
            onClick={() => setSlide(
                (prev: any) => handleArrow(currentIndex, CAROUSEL_NEXT_LABEL)
            )}>
            <ArrowForwardIosIcon />
        </NextArrow>
        }
        <Indicators>
            {[...Array(Math.ceil(slider.length))].map(
                (item:any, index: number) =>
                <span
                key={index}
                className={currentIndex === index ? 'active' : ''}
                onClick={
                    () => setSlide(index)
                }>
                    <CircleIcon />
                </span>
            )}
        </Indicators>
    </Container>
    )
}