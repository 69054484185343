export const Team =[
    {
        name:'Damian Soria',
        image:'/profile-1.jpg',
        role:'Frontend Developer',
        role2:''
    },
    {
        name:'Romina Roldan',
        image:'/profile-2.jpg',
        role:' Project Manager/',
        role2:'Product Owner'
    },
    {
        name:'Martin Roman',
        image:'/profile-3.jpg',
        role:'Backend Developer',
        role2:''
    },
    {
        name:'Luciana Britos',
        image:'/profile-4.jpg',
        role:'Frontend Developer',
        role2:''
    },
    {
        name:'Alexandra Astudillo',
        image:'/profile-5.jpg',
        role:'QA Tester',
        role2:''
    },
    {
        name:'Diana Silva',
        image:'/profile-6.jpg',
        role:'UX/UI Designer',
        role2:''
    },

]