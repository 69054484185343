export const ACTIVE_LABEL = 'Active';
export const ARCHIVED_LABEL = 'Archived';
export const COMPLETED_LABEL = 'Completed';
export const DOWNLOAD_LABEL = 'Download';
export const DUE_DATE_CLIENT = 'dueDateClient';
export const EDIT_LABEL = 'Edit';
export const FEES_LABEL = 'Fees';
export const NOTCOMPLETED_LABEL = 'Not Completed';
export const NOTEVENT_LABEL = 'Not Event';
export const OTHERLABEL = 'Other'
export const OTHERSLABEL = 'Others'
export const PATH_IMAGES = process.env.PUBLIC_URL + '/images/';
export const prefix = 'projectInformation';
export const RESTORE_LABEL = 'Restore';
export const VIEW_LABEL = 'View';
export const isMobile = (window.innerWidth <= 760)

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
}

const GROUP_SIZE = 3;
export function groupedItems(items: any, groupSize?: number) {
    return items.reduce(
        (acc: any, item: any) => {
            if (acc[acc.length - 1].length >= (groupSize || GROUP_SIZE)) {
                return [...acc, [item]];
            }
            acc[acc.length - 1].push(item);
            return acc;
        },
        [[]]
    )
}

export const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
    mobileMax: `(max-width: ${sizes.mobileM})`,
    laptopMax: `(max-width: ${sizes.laptop})`,
    tabletMax: `(max-width: ${sizes.tablet})`,
    desktopMax: `(max-width: ${sizes.desktop})`,

}

export const Actions = {
    delete: 'delete',
    add: 'add',
}

export function isObjectEmpty(objectName: any) {
    return Object.keys(objectName).length === 0
}

export function getBase64(file: any) {
    const reader = new FileReader();
    let result;
    reader.readAsDataURL(file);
    reader.onload = function () {
        result = reader.result
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
    return result;
}

export function uploadFileMock(fileObject: any) {
    return new Promise((resolve, reject) => {
        resolve({
            url: 'https://cdn.filestackcontent.com/nInu2RCvTISx3ZluTzxy'
        })
        reject()
    })
}

export function capitalized(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1)
}

export function convertDate(timestamp: number, useSeconds: boolean) {
    const value = useSeconds ? timestamp * 1000 : timestamp;
    return new Date(value).toLocaleDateString()
}

export function generateId() {
    return Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
}