import { FC, ReactNode } from 'react';
import MUIButton from '@mui/material/Button';
import styled from 'styled-components';

interface ButtonProps {
    type?: "button" | "submit" | "reset" | undefined,
    children: ReactNode,
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    sx?: any,
    disabled?: any,
    variant: "text" | "outlined" | "contained" | undefined,
}

const ButtonUI = styled(MUIButton)`
    background-image: linear-gradient(-180deg, rgb(20, 92, 253) 0%, rgb(61, 237, 184) 96%);    
    border-radius:10px !important;
    font-family: ${({ theme }) => theme.font.primary}!important;
    font-size:.8rem!important;
    font-weight: bold!important;
    max-width: 10rem;
    width: 100%;
    box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.50);
    -webkit-box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.50);
    -moz-box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.50);

    :hover {
        border:none !important;
    }

`;

const Button: FC<ButtonProps> = ({
    type,
    children,
    variant,
    onClick,
    disabled,
    sx
}) => {
    return (
        <ButtonUI
            type={type}
            variant={variant}
            onClick={onClick}
            disabled={disabled}
            sx={sx}>
            {children}
        </ButtonUI>
    );
}

export default Button;