import styled from "styled-components";

export const BannerBg = styled.div`
    background-image:url("images/home-hero.png");
    background-repeat: no-repeat;
    background-position-x: right;
    background-size:50rem;
    height:100%;
    max-height:30rem;
    min-height:25rem;
    width:100%;
    display:flex;
    padding:2rem 0;
    @media (min-width:375px) and (max-width:500px){
        min-height: 20rem;
        background-position-y: center;
        background-position-x: left;
        background-size: 50rem;
    }
`
export const ContentBanner = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:flex-start;
    width: 100%;
    max-width: 30rem;
    padding:1rem 2rem;
    @media (min-width:375px) and (max-width:500px){
        height: 2rem;
        padding: 3rem 2rem 0;
    }
    @media (min-width:500px) and (max-width:700px){
        display:none;
    }
`