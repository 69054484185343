import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { AppBar } from '@mui/material';
import { PATH_IMAGES, isMobile } from '../../../utils';
import { Dflex } from '../../../pages/Home/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Theme from '../../../Theme';
import Typography from '../../atoms/Typography';
import { Link } from 'react-scroll';
import { LogoHeader } from './styles';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const navItems = ['Home', 'Services', 'Our Team', 'Contact'];

export default function Header(props: Props) {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', alignItems:'center', display:'flex', flexDirection:'column', marginTop:'1rem' }}>
            <Dflex align='center' margin='0' direction='column'>
                <img src={`${PATH_IMAGES}/logo.png`} alt='logo' width={60} style={{margin: '0 1rem 0 0'}}/>
                <Typography variant="h6" margin='1rem' style={{ my: 2 , color:Theme.palette.primary ,fontFamily:Theme.font.primary}} >
                    SkyBit
                </Typography>
            </Dflex>
            <Divider />
            <List>
                {navItems.map((item) => (
                <ListItem key={item} disablePadding>
                    <Link style={{margin:'0 0.5rem', fontFamily:Theme.font.primary, cursor:'pointer'}}
                    key={item}
                    activeClass="active" 
                    to={item}
                    smooth={true} 
                    offset={-100} 
                    duration={500} 
                    onClick={()=> setMobileOpen(false)}
                >
                        <ListItemText primary={item} />
                    </Link>
                </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
    <Box sx={{ display: 'flex'}}>
        <CssBaseline />
        <AppBar component="nav" sx={{ background: 'white', boxShadow: 'none'}}>
            <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <Dflex align='center'style={{display:'flex'}} >
                    <MenuIcon sx={{ color: 'black'}}/>
                </Dflex>
            </IconButton>
            <Typography
                variant="h6"
                margin='1rem 0'
                color="primary"
                style={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } , color:Theme.palette.primary, }}
            >
                <LogoHeader align={isMobile ? 'flex-start' : 'center'}   direction={isMobile ? 'column' : 'row'} margin={isMobile ? '0 5rem':'0'} justifyContent={isMobile ? 'center':'left'}>
                    <img src={`${PATH_IMAGES}/logo.png`} alt='logo' width={50} style={{margin: '0'}}/> 
                    <Typography variant='h6' margin={isMobile ? '0' : '0 1rem'}  color='primary' fontSize={isMobile ? '1rem' : '1.3rem'} >
                        SkyBits
                    </Typography>
                </LogoHeader>
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' }, color:Theme.palette.primary, margin:'0 1rem 0 0' }}>
                {navItems.map((item) => (
                <Link style={{margin:'0 0.5rem', fontFamily:Theme.font.primary, cursor:'pointer'}}
                    key={item}
                    activeClass="active" 
                    to={item}
                    smooth={true} 
                    offset={-100} 
                    duration={500} 
                    onSetActive={()=>{}}
                >
                    {item}
                </Link>
                ))}
            </Box>
            </Toolbar>
        </AppBar>
        <nav>
            <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                background:'#f6f7f8'
            }}
            >
            {drawer}
            </Drawer>
        </nav>
        <Box component="main" sx={{ p: 0 }}>
            <Toolbar />
        </Box>
    </Box>
    );
}