const Theme = {
  font: {
    primary: 'Montserrat',
  },
  palette: {
    white: '#FFF',
    primary: '#3dedb8',
    black: '#000',
    gray: '#666666',
    turquoise: '#3dedde'
  },
}
export default Theme