import styled from "styled-components";

export const LogoHeader = styled.div<{
    align:string,
    justifyContent:string,
    direction:string,
    margin:string
}>`
    display:flex;
    align-items:${({align}) => align};
    flex-direction:${({direction}) => direction};
    margin:${({margin}) => margin};
    justify-content:${({justifyContent}) => justifyContent};
`