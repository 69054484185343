import { isMobile } from "../../../utils";
import Button from "../../atoms/Button";
import Typography from "../../atoms/Typography";
import { BannerBg, ContentBanner } from "./styles";
import { animateScroll as scroll } from 'react-scroll';

const Banner = () => {
    const cssTitle = isMobile ? {
        color: 'turquoise',
        fontSize: '2rem',
    } : {
        color: 'black',
        fontSize: "2.895rem"
    }
    return (
    <BannerBg>
        <ContentBanner>
            <Typography
                variant="h1"
                align="left"
                margin="1rem"
                {...cssTitle}
            >
                IT Services and Solutions            
            </Typography>
            <Typography 
                variant="h2"
                color="primary"
                fontSize="1.34rem"
                align="left"
                margin="1rem"
                className="onlyDesktop"
            >
                Guaranteed high quality
            </Typography>
            <Typography variant="p" color="#000" padding="0 1rem 1rem 1rem" className="onlyDesktop">
                SkyBits Solutions is a dynamic IT agency 
                that aims to provide the most effective 
                services and apps to its customers.
            </Typography>
            <Button
                variant="contained"
                onClick={() => scroll.scrollToBottom()}
                sx={{margin:'0 1rem', color:'#fff'}} >
                Contact Us
            </Button>
        </ContentBanner>
    </BannerBg>
    );
}

export default Banner;