import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
    height:100%;
`
export const FlexColumn = styled.div<{
    align?:string
}>`
    display:flex;
    flex-Direction:column;
    align-items:${({align = 'center'})=> align };
    width:100%;
    justify-content:space-around;
    margin-top: 1rem;
    height: 100%;
    gap: 1.5rem;
    padding:1rem;
    @media (min-width:375px) and (max-width:500px){
        max-height:110rem;
        justify-content:center;
    }
    @media (min-width:500px) and (max-width:700px){
        max-height:45rem;
        justify-content:center;
c    }
    @media (min-width:700px) and (max-width:960px){
        max-height:45rem;
        justify-content:space-around;
    }
}
`
export const Dflex = styled.div<{
    justifyContent?:'center' |'space-around'| 'space-between'| 'right'| 'left'
    align?: 'center' | 'flex-start'| 'flex-end'
    width?: string
    margin?: string
    direction?: string
}>`
    flex-wrap: wrap;
    display: flex;
    flex-direction: ${({ direction='row' }) => direction};
    width:${({width})=> width};
    height:100%;
    align-items: ${({align}) => align};
    justify-content: ${({justifyContent}) => justifyContent};
    margin: ${({ margin= '1rem' })=> margin};
    @media (min-width: 375px) and (max-width: 500px){
        justify-content:center;
    }
    @media (min-width: 500px) and (max-width: 700px){
        justify-content:space-around;
    }
    @media (min-width: 700px) and (max-width: 950px){
        justify-content:space-around;
    }
`
export const BgPanel = styled.div`
    background-image:url('./images/bg-center.jpg');
    background-position-y: center;
    border:1px solid #ccc;
    border-radius:1px;
    padding: 2rem 2rem 4rem 2rem;
    margin: 0 0 1rem 0;
`
export const ServicesContainer = styled.div<{
    align?:string,
    width?:string,
    margin?:string,
    justifyContent?: string
}>`
    display:flex;
    flex-wrap:wrap;
    margin:${({ margin }) => margin};
    align-items:${({align}) => align};
    width:${({ width }) => width};
    justify-content:${({justifyContent}) => justifyContent};
`
export const Footer = styled.div`
    display:flex;
    height:100%;
    width:100%;
    background-color: #666;
    align-items: center;
    justify-content:space-between;
`
export const LogoFooter = styled.div<{
    margin?:string,
    direction?: string,
    align?:string
}>`
    display:flex;
    padding:1rem;
    margin:${({ margin }) => margin};
    flex-direction:${({ direction }) => direction};
    align-items:${({ align }) => align};
    height:100%;
    width:100%;
    max-width:8rem;

    @media (min-width:375px) and (max-widht:500px){
        justify-content: center;
        padding: 1rem 0;
    }
`
export const Img = styled.img<{
    padding?:string
}>`
    color:${({theme})=> theme.palette.white} !important;
    padding:${({padding}) => padding};
`

export const Social = styled.div<{
    width:string
}>`{
    align-items:center;
    display:flex;
    justify-content:end;
    margin:0 1rem;
    width: ${({ width })=> width};
}`