import styled from "styled-components";
import Theme from "../../../Theme";

export const ContainerCard = styled.div`
    display: flex;
    flex-direction:column;
    align-items:center;
    color:${Theme.palette.primary};
    justify-content:flex-start;
    text-align:center;
    max-width:12rem;
    min-width:8rem;
    height:23rem;
    width:100%;
    margin:1rem 2rem;
    border: 1px solid #3dedb8 !important;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.50);
    -webkit-box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.50);
    -moz-box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.50);

    :hover {
        transform: scale(1.2);
        transition: 0.3s;
        font-size:0.8rem;
        color:${Theme.palette.primary};
    }

    @media (max-width: 560px) {
        max-width: 14rem;
    }
`