import React, { createContext, ReactNode } from 'react';

export const AppContext = createContext<{
    store: {
        requestAll: any,
        formSelected: any,
        formCompleted: any,
        userContact: any,
        stepCompleted: string[]
    },
    setStore: React.Dispatch<any>
}>({
    store: {
        requestAll: [],
        formSelected: {},
        formCompleted: {},
        userContact:{},
        stepCompleted: [],
    },
    setStore: () => {}
})

const InitDefault = {
    requestAll: [],
    formSelected:"",
    formCompleted: {},
    userContact:{},
    stepCompleted: [],
}

export function AppProvider({
    children,
}:{
    children: ReactNode
}) {
    const [store, setStore] = React.useState(InitDefault)
    return <AppContext.Provider value={{
        store,
        setStore
    }}>
        {children}
    </AppContext.Provider>
}