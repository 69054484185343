import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 24rem;
    width: 100%;
`
export const TextArea = styled.textarea`
    background: ${({ theme }) => theme.palette.white};
    font-family: ${({ theme }) => theme.font.primary};
    border-radius: .5rem;
    border: 1px solid ${({ theme }) => theme.palette.white};
    color: ${({ theme }) => theme.palette.black};
    height: 5rem;
    outline: none;
    padding: .5rem;
    resize: none;
    width: 100%;
    max-width:30rem;

    &::placeholder {
        color: rgba(0, 0, 0, 0.6)!important;
        font-family: ${({ theme }) => theme.font.primary};
        font-size: 1rem;
        font-weight: 500;
    }
    &:disabled {
        color: ${({theme})=> theme.palette.black}!important;
        background: rgba(217, 217, 217, 0.2);
    }
    @media (min-width:375px) and (max-width:500px){
        max-width:22rem;
        width:100%;
    }
`
export const MaxAlert = styled.div`
    color: ${({ theme }) => theme.palette.redLight};
    display: flex;
    font-size: 0.75rem;
    justify-content: flex-end;
    margin: .2rem 0 0;
    width: 100%;
`