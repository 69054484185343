import styled from "styled-components"
import TextFieldMt from "@mui/material/TextField";

export const BgForm = styled.div`
    aling-items:center;
    background: linear-gradient(90deg, rgba(20,197,253,0.5215336134453781) 41%, rgba(61,237,152,1) 100%);
    justify-content: space-around; 
    display:flex;
    width:100%;
    height:100%;
`
export const Form = styled.form`
    align-items:center;
    display:flex;
    padding:1rem;
    gap:1rem;
    flex-direction:column;
    width:100%;
    height:100%;
    font-family:${({ theme }) => theme.font.primary};

    @media (min-width:375px) and (max-width:500px){
        min-width:10rem;
        justify-content: center;
        margin: 0;
    }
`
export const TextField = styled(TextFieldMt)`
    background-color:${({theme})=> theme.palette.white};
    border-radius:10px;
    border-color:none!important;
    max-height:3rem;
    max-width:30rem;
    height:100%;
    width:100%;
    &:hover {
        border-color:none!important;
    }
    & .MuiOutlinedInput-notchedOutline {
        border:none;
    }
    @media (min-width:375px) and (max-width:500px){
        max-width:22rem;
        width:100%;
    }
`