import React from 'react'
import styled, { css } from 'styled-components'
import { devices } from '../../../utils'

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label' | 'p' | 'span' | 'linkLabel' | 'loginLabel' | 'versionLabel'
interface TypographyProps {
  align?: 'center' | 'left' | 'right'
  alignSelf?: 'flex-start' | 'flex-center' | 'flex-end'
  justifyContent?: 'center' | 'space-between' | 'space-around'
  bold?: boolean
  children?: any
  className?: string
  color?: string | undefined
  fontSize?: string
  htmlFor?: string
  lineHeight?: string
  margin?: string
  onClick?: () => void
  padding?: string
  textTransform?: 'uppercase' | 'capitalize' | 'lowercase'
  style?: any
  width?: string
  maxWidth?: string
  variant: Variant
}

const components: any = {
  h1: styled.h1<TypographyProps>`
    color: ${({ theme, color = 'primary' }) => theme.palette[color]};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ fontSize }) => fontSize ?? '1.8rem'};
    font-weight: 700;
    margin: ${({ margin }) => margin};
    text-align: ${({ align = 'left' }) => align};
    text-transform: ${({ textTransform }) => textTransform};
    ${({ style }) => style ?? ''}
  `,
  h2: styled.h2<TypographyProps>`
    color: ${({ theme, color = 'primary' }) => theme.palette[color]};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ fontSize = '1.2rem' }) => fontSize};
    font-weight: 700;
    line-height: ${({ lineHeight })=> lineHeight};
    margin: ${({ margin }) => margin};
    ${({padding})=> padding ? `padding: ${padding};` : ""}
    text-align: ${({ align }) => align};
    text-transform: ${({ textTransform }) => textTransform};
    width: ${({ width }) => width};
    max-width: ${({ maxWidth }) => maxWidth};
    ${({ style }) => style ?? ''}

  `,
  h3: styled.h3<TypographyProps>`
    color: ${({ theme, color = 'black' }) => theme.palette[color]};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ fontSize = '1rem' }) => fontSize};
    font-weight:${({ bold ='600' })=> bold='500'};
    margin: ${({ margin }) => margin};
    line-height: ${({lineHeight})=> lineHeight}
    padding:0 1rem;
    text-align: ${({ align = 'left' }) => align};
    text-transform: ${({ textTransform }) => textTransform};
    ${({ style }) => style ?? ''}

  `,
  h4: styled.h4<TypographyProps>`
    color: ${({ theme, color = 'black' }) => theme.palette[color]};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ fontSize = '1rem' }) => fontSize};
    font-weight: 700;
    margin: ${({ margin }) => margin};
    text-align: ${({ align }) => align};
    text-transform: ${({ textTransform }) => textTransform};
    max-width:${({ maxWidth }) => maxWidth};
    width:${({ width }) => width};
    ${({ style }) => style ?? ''}

  `,
  h5: styled.h5<TypographyProps>`
    color: ${({ theme, color = 'black' }) => theme.palette[color]};
    text-align: ${({ align = 'left' }) => align};
    text-transform: ${({ textTransform }) => textTransform};
    ${({ style }) => style ?? ''}

  `,
  h6: styled.h6<TypographyProps>`
    aling-self:${({alignSelf})=> alignSelf};
    color: ${({ theme, color = 'black' }) => theme.palette[color]};
    font-size: ${({ fontSize = '1rem' }) => fontSize};
    font-weight: 400;
    line-height: 1.25rem;
    margin: ${({ margin }) => margin};
    text-align: ${({ align = 'left' }) => align};
    text-transform: ${({ textTransform }) => textTransform};
    ${({ style }) => style ?? ''}

  `,
  label: styled.label<TypographyProps>`
    ${({
      theme,
      align = 'left',
      alignSelf,
      bold = true,
      color = 'primary',
      fontSize = '0.9rem',
      justifyContent,
      margin,
      padding,
      style,
    }) => css`
      ${alignSelf ? `align-self: ${alignSelf};` : ""}
      ${justifyContent ? `justify-content:${justifyContent};` : ''}
      ${margin ? `margin: ${margin};` : ""}
      ${padding ? `padding: ${padding};` : ""}
      ${style ?? ''}
      color: ${theme.palette[color]};
      font-family: ${theme.font.primary};
      font-size: ${fontSize};
      font-weight: ${bold ? '700' : '500'};
      text-align: ${align};
    `}
    @media ${devices.mobileS} and (max-width: 35rem) {
      font-size: .8rem;
    }
  `,
  p: styled.p<TypographyProps>`
    ${({
      theme,
      align = 'left',
      alignSelf,
      color = 'primary',
      fontSize = '1rem',
      justifyContent,
      margin,
      padding,
      style,
    }) => css`
      ${alignSelf ? `align-self: ${alignSelf};` : ""}
      ${justifyContent ? `justify-content:${justifyContent};` : ''}
      ${margin ? css`margin: ${margin};` : ""}
      ${padding ? css`padding: ${padding};` : ""}
      ${style ?? ''}
      color: ${color};
      font-family: ${theme.font.primary};
      font-size: ${fontSize};
      text-align: ${align};
    `}
  `,
  span: styled.span<TypographyProps>`
    font-size: ${({ fontSize = '0.85rem' }) => fontSize};
    ${({ theme, color = 'primary', margin }) => css`
      font-family: ${theme.font.primary};
      color: ${theme.palette[color]};
      ${margin ? `margin: ${margin};` : ""};

    `}
  `,
  linkLabel: styled.span<TypographyProps>`
    color: ${({ color, theme }) => color ?? theme.palette.grey_medium};
    cursor: pointer;
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ fontSize = '0.85rem' }) => fontSize};
    font-weight: 600;
    line-height:1.5rem;
  `,
  loginLabel: styled.span<TypographyProps>`
    color: ${({ theme }) => theme.palette.grey_medium};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ fontSize = '0.85rem' }) => fontSize};
    font-weight: 600;
    line-height:1.5rem;
  `,
  versionLabel: styled.span<TypographyProps>`
    color: ${({ theme }) => theme.palette.version};
    font-family: ${({ theme }) => theme.font.primary};
    font-size: ${({ fontSize = '1.04rem' }) => fontSize};
    font-weight: 600;
    line-height: 1.5rem;
  `,
}

const Typography: React.FC<TypographyProps> = ({
  variant,
  children,
  ...props
}: TypographyProps) => {
  const El = components[variant]
  return <El {...props}>{children}</El>
}

export default Typography
