import styled, { css } from "styled-components"
import { Carousel } from 'react-responsive-carousel';
import Theme from "../../../Theme";

export const CAROUSEL_PREV_LABEL = 'prev'
export const CAROUSEL_NEXT_LABEL = 'next'
export const cssTitle = {
    background: Theme.palette.white,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '2rem',
    justifyContent: 'center',
    margin: '0',
    textAlign: 'center',
    width: '100%',
};

export const Container = styled.div`
    margin: 1rem 0 0;
    position: relative;
`

export const CarouselRP = styled(Carousel)`
    & .slider-wrapper {
        width: 90%;
    }
    & .slide {
        padding: 0 .5rem;
    }
`
export const CarouselItem = styled.div<{
    background: string,
}>`
    ${({ theme, background }) => css`
        align-items: flex-end;
        background: ${theme.palette.secondary};
        ${background && css`background: url(${background}) no-repeat center top`};
        background-size: 100%;
        background-position: cover;
        border-radius: 10px;
        display: flex;
        width: 100%;
        max-width: 18.75rem;
        height:100%;
        min-height: 20rem;
        justify-content: center;
        margin: 1rem 0;
        min-height: 28rem;
    }
        &:hover {
            border: 1.5px solid #00ffff;
        }
    `}
`

const Arrows = styled.div`
    background: rgba(255,255,255, 0.5);
    border-radius: 10rem;
    cursor: pointer;
    padding: .5rem;
    position: absolute;
    top: 35%;
    z-index: 2;
`

export const PrevArrow = styled(Arrows)`
    left: 0.625rem;
`

export const NextArrow = styled(Arrows)`
    right: 0.625rem;
`

export const Indicators = styled.div`
    margin: .5rem 0 0;
    text-align: center;
    width: 100%;
    & span svg {
        cursor: pointer;
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.08);
    }

    & span.active svg,
    & span svg: hover {
        color: ${({theme})=> theme.palette.primary};
    }
`